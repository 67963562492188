@import "./variables";
@import "./toast";

@keyframes shake {
  // 10%, 90% {
  //   transform: translate3d(-1px, 0, 0);
  // }

  // 20%, 80% {
  //   transform: translate3d(2px, 0, 0);
  // }

  // 30%, 50%, 70% {
  //   transform: translate3d(-4px, 0, 0);
  // }

  // 40%, 60% {
  //   transform: translate3d(4px, 0, 0);
  // }
  0% {
    transform: translate3d(0px, 0, 0);
  }
  16.66%,
  50%,
  83.33% {
    transform: translate3d(5px, 0, 0);
  }
  33.33%,
  66.66% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: translate3d(0px, 0, 0);
  }
}

#commentBox {
  label {
    margin-bottom: 0px;
  }
  .form-control {
    border-radius: 0px;
    height: 8em;
  }
  button {
    margin-top: 0.25em;
    float: right;
  }
}

@media (max-width: $tablet) {
  #commentBox {
    button {
      width: 100%;
    }
  }
}
