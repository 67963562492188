@import "./variables";

.navbar {
  background-color: $white !important;
  padding: 0px !important;
  height: 125px;
  .navbar-brand {
    padding: 0px;
    width: 125px;
    height: 125px;
    margin: 0px;
    a {
      height: 100%;
      width: 100%;
      img {
        box-sizing: border-box;
        width: 125px;
        padding: 23.05px 25px 20px;
        background-color: $emoryblue;
      }
    }
  }
  .navbar-toggler {
    padding: 0px;
    border: 0px;
  }
  .navbar-collapse {
    float: left !important;
  }
  .flex-column {
    background-color: $white;
    width: 100%;
    height: 100%;
    h1 {
      font-size: 3em;
      color: $emoryblue;
      height: 80px;
      line-height: 80px;
      padding: 0px 10px;
      text-align: left;
      margin-bottom: 0px;
      cursor: default;
    }
    #basic-navbar-nav {
      padding: 0px 10px;
      background-color: $gray-100 !important;
      width: 100%;
      height: 45px;
      .navbar-nav {
        .nav-link {
          padding: 0.25em 0.5em;
          border: 2px solid $gray-100;
          color: $emoryblue;
          &:hover {
            color: $blue;
            border: 2px solid $blue;
          }
        }
        .dropdown {
          a {
            &::after {
              color: $yellow;
            }
          }
          .dropdown-menu {
            border: 1px solid $gray-200;
            border-radius: 0px;
            a:hover {
              background-color: $gray-200;
            }
            a:active {
              color: $white;
              background-color: $yellow;
            }
          }
          .dropdown-text {
            color: $gray-600;
            margin: 0px;
            padding: 0.25rem 1.5rem;
            cursor: default;
          }
        }
      }
    }
  }
}

.svg-bg {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}

@media (max-width: $tablet) {
  .navbar {
    justify-content: left !important;
    height: 55px;
    .navbar-brand {
      padding: 0px;
      width: 55px;
      height: 55px;
      a {
        img {
          overflow: hidden;
          box-sizing: border-box;
          width: 55px;
          height: 55px;
          padding: 4px;
          background-color: $emoryblue;
        }
      }
    }
    .flex-column {
      width: calc(100% - 111px);
      h1 {
        font-size: 2em;
        height: 55px;
        line-height: 55px;
        overflow: hidden;
      }
      #basic-navbar-nav {
        height: auto;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        // this is: calc(100% + width of brand/logo div + width of hamburger button)
        width: calc(100% + 55px + 54px);
        // brand/logo is 55px wide, this pushes div to left of screen
        margin-left: -55px;
        .form-inline {
          .form-control {
            width: auto;
          }
        }
      }
    }
    .navbar-toggler {
      border: 0px;
      // background-color: $white;
    }
  }
}
