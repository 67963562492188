@import "./variables";
@import "./newcastleSurvey.scss";

.article-form {
  .row {
    margin-bottom: $spacer;
  }

  &__pubmed-fields {
    .abstract {
      background: $gray-200;
      padding: 0.5 * $spacer;

      p {
        margin-bottom: 0;
      }
    }
  }

  &__cases {
    &__case {
      border: 1px solid $gray-400 !important;

      .row:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__morningside-summary {
    &__editor {
      border: 1px solid $gray-400;
      width: 100%;
      min-height: 10rem !important;
      padding: 0.5 * $spacer;
    }
  }

  .checkbox-group {
    label:hover {
      border: 1px solid $gray-400;
      border-radius: $border-radius !important;
    }
  }
}

.medium-editor-toolbar {
  background: none !important;
  background-color: none !important;
  li {
    button {
      background: none !important;
      background-color: $emoryblue !important;
      &:hover {
        color: $yellow !important;
      }
    }
  }
}
