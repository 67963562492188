@import "./variables";

#breadcrumb-bar {
  .breadcrumb {
    font-family: "Noto Sans", sans-serif;
    padding: 0.25rem 1rem;
    background-color: $white;
    border-radius: 0px;
    .breadcrumb-item {
      &.active {
        color: $gray-600;
        cursor: default;
      }
      & + .breadcrumb-item::before {
        color: $yellow;
        font-size: 0.5em;
        margin-top: 1em;
        content: "►";
      }
    }
  }
}
