@import "./_variables.scss";

#tmp-typechooser {
  max-width: 100px;
  margin-bottom: 2rem;
}

#newcastle-survey {
  max-width: calc(400px + 1rem);
  background-color: $gray-200;
  padding: 1.25rem;
  margin-bottom: 0.5rem;
  .survey-group {
    max-width: 400px;
    margin-bottom: 1rem;
    label {
      color: $gray-900;
      margin-bottom: 2px;
    }
    input {
      width: auto;
    }
  }
}
