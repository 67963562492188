@import "./_variables.scss";

#article-page {
  h1 {
    font-size: 1.338em;
  }
  h2 {
    color: $darkGray;
    font-size: 1.33em;
  }
  #article-details {
    padding: 0.5rem 1rem;
    h3 {
      color: $darkGray;
      font-size: 1.8rem;
      font-weight: 1000;
    }
    h4 {
      margin: 0 0 0.1rem 0.5rem;
      font-size: 1.2rem;
    }
    p {
      margin-left: 1rem;
    }
    .details-group {
      background-color: $gray-100;
      border: 1px solid $gray-200;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;
      p {
        &:last-of-type {
          margin-bottom: 0.5rem;
        }
      }
    }
    .card {
      margin-bottom: 1rem;
      .accordion-button {
        box-shadow: unset !important;
      }
      .card-header {
        cursor: pointer;
        font-size: 1.33em;
        font-weight: 600;
        color: white;
        background-color: $emoryblue;
        border: 1px solid $emoryblue;
        padding: 0.5rem 0.75rem;
      }
      .card-body {
        border: 1px solid $emoryblue;
        p {
          &:last-of-type {
            margin-bottom: 0.5rem;
          }
        }
        p.morningsideSummary {
          white-space: pre-wrap;
        }
        table {
          width: 100%;
          border: 1px solid $gray-400;
          thead {
            background-color: $gray-100;
          }
          th,
          td {
            padding: 0px 1px 0px 3px;
          }
        }
      }
      &:hover {
        .card-header {
          background-color: lighten($emoryblue, 10);
          border: 1px solid lighten($emoryblue, 10);
        }
        .card-body {
          border: 1px solid lighten($emoryblue, 10);
        }
      }
      .definitions-accordion {
        .card {
          border: 0px;
          margin: 0px;
          .accordion-button::after {
            display: none;
          }
          .card-header {
            background-color: #ffffff00;
            border: 0px;
            padding: 0px;
            &:hover {
              cursor: default;
            }
            .btn {
              border-color: $yellow;
              background-color: $yellow;
              color: $white;
              padding: 2px 4px;
              &:hover {
                background-color: darken($yellow, 10);
                text-decoration: none;
              }
              &:focus {
                box-shadow: none;
                text-decoration: none;
              }
            }
          }
          .collapse,
          .collapsing {
            border: 0px;
            .card-body {
              border: 0px;
              padding: 0px;
              p {
                margin: 0rem 0rem 0.25rem 0rem;
              }
            }
          }
        }
      }
    }
    #affiliationsAccordion {
      margin: -0.75em 1em 1em;
      .card {
        border: 0px;
        margin: 0px;
        .card-header {
          color: $black;
          font-size: 1em;
          font-weight: 400;
          background-color: #ffffff00;
          border: 0px;
          padding: 0px;
          &:hover {
            cursor: default;
          }
          .btn {
            background-color: $white;
            color: $black;
            border: 1px solid black;
            margin: 0 0 1px 0;
            padding: 2px 4px;
            font-weight: 400;
            &:hover {
              background-color: darken($white, 10);
              text-decoration: none;
            }
            &:focus {
              box-shadow: none;
              text-decoration: none;
            }
          }
        }
        .collapse,
        .collapsing {
          border: 0px;
          .card-body {
            border: 0px;
            border-left: 0.125em solid $gray-500;
            margin: 0.25em 0 0 0;
            padding: 0 0 0 0.25em;
            p {
              margin: 0rem 0rem 0.25rem 0rem;
            }
          }
        }
      }
    }
    .missing-info {
      color: $gray-600;
    }
  }
  .article-tools {
    margin: 0.5rem;
    .btn {
      background-color: $yellow;
      border: 1px solid $yellow;
      word-wrap: break-word;
      width: 100%;
      font-size: 1.1rem;
      margin-bottom: 1rem;
      &:hover {
        background-color: darken($yellow, 10);
        border: 1px solid darken($yellow, 10);
      }
    }
    .doi {
      color: $gray-700;
    }
  }
}
