@import "./variables";
@import "./_Bootstrap.scss";
@import "./breadcrumb.scss";
@import "./card.scss";
@import "./navbar.scss";
@import "./footer.scss";
// Bootstrap is imported at bottom!

// bespoke pages
@import "./articlepage.scss";
@import "./homepage.scss";
@import "./faq.scss";
@import "./newarticlepage.scss";
@import "./searchpage.scss";
@import "./toast.scss";

body {
  //
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
  //
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  a {
    color: $emoryblue;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  background-color: $gray-200;
  #navbar-content {
    padding: 0.5em 1em;
  }
}

h1,
h2 {
  font-family: "Spectral", serif;
  font-weight: 700 !important;
  color: $emoryblue !important;
}

h3,
h4,
h5 {
  color: $gray-700;
}

p,
a,
li {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

p {
  color: black;
}

a {
  color: $emoryblue;
}

hr {
  margin: 0.5em 0px;
}

.btn-primary {
  background-color: $emoryblue;
  &:hover {
    background-color: lighten($emoryblue, 10);
  }
}

.loadingIndicator {
  color: $darkBlue;
  margin: 2em auto;
  height: 1em;
  display: block;
  animation: fa-spin 2s infinite cubic-bezier(0.78, 0.12, 0.32, 0.93);
  -webkit-animation: fa-spin 2s infinite cubic-bezier(0.78, 0.12, 0.32, 0.93);
}

#main-spinner-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: $white;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    color: $emoryblue;
    display: block;
  }
}

.form-control {
  &.error-color {
    border: 1px solid $red;
    background-color: lighten($red, 42%);
    transition: 0.5s;
  }
  &.error-animation {
    animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
}

// scrollbar
/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray-100;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $emoryblue;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lighten($emoryblue, 20);
}

@import "~bootstrap/scss/bootstrap";
// import Google Fonts Spectral
@import url("https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap");
// import Google Fonts Noto Sans
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
