@import "../../styles/variables";

.clinical-outcomes {
  &__outcome.card {
    border: 1px solid $gray-400 !important;

    .row {
      margin-bottom: 0;
    }
  }
}
