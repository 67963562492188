@import "./variables";

#status-flow {
  padding: 0.75rem;
  // background-color: $gray-400;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  button {
    border: none;
    margin: 0 0.125rem;
    width: 100%;
    -ms-transform: skewX(-25deg);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
    &#current {
      border: 0.25rem solid $yellow;
      cursor: auto;
    }
    span {
      -ms-transform: skewX(25deg);
      -webkit-transform: skewX(25deg);
      transform: skewX(25deg);
      display: inline-block;
    }
    &:disabled {
      color: $black;
      background-color: $gray-400;
      opacity: 1;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
