// this file is for re-defining default Bootstrap
//
// it prevents the JSX and SASS from getting clogged
// with unnecessary classes and IDs that only serve
// to undo default Bootstrap styling

@import "./variables.scss";

// redefine default colors with Emory
// brand colors from variables.scss
$primary: $emoryblue;
$secondary: $blue;
$success: $green;
$danger: $red;
// $warning: $yellow;
// $info: $teal;
$light: $gray-200;
$dark: $gray-900;
$muted: $gray-600;
$white: $white;

// sticks footer at bottom:
html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
//

// _____________________________________________
//
// Component-specific styling changes:

.container {
  margin-top: 1rem;
  margin-bottom: 6rem;
}

// input,
// button {
//   border-radius: 0px !important;
// }

.modal {
  .modal-content {
    border: none;
    border-radius: 0;
    .modal-header {
      h2 {
        margin-bottom: 0;
      }
      padding: 0.75rem;
      color: $emoryblue;
      border-bottom: none;
    }
    .modal-body {
      padding: 0 1rem;
      p {
        margin-bottom: 0.75rem;
      }
      ol {
        margin-bottom: 1.5rem;
        color: $black;
        list-style-type: none;
        text-indent: -0.75em;
      }
    }
  }
}

.nav-tabs {
  a.nav-link {
    color: $muted;
    border-bottom: 0px;
  }
  a.nav-link.active {
    color: $primary;
  }
}
.tab-content {
  .tab-pane {
    .table-bordered {
      border-top: none;
      tr {
        th {
          border-top: none;
        }
      }
    }
  }
}
