@import "../../styles/variables";

.my-work {
  .nav-tabs {
    .nav-item {
      border: 1px solid $gray-200 !important;
      border-radius: $border-radius $border-radius 0 0;
      &:hover {
        border: 1px solid $gray-500 !important;
        color: $emoryblue;
        border-bottom: none !important;
      }
      border-bottom: none !important;
    }

    #my-work__tabs-tab {
      &-case-report.active {
        background-color: $purple;
        border: 1px solid $purple;
        color: white;
      }
      &-clinical-trial.active {
        background-color: $green;
        border: 1px solid $green;
        color: white;
      }
      &-observational-result.active {
        background-color: $red;
        border: 1px solid $red;
        color: white;
      }
    }

    .active {
      border-bottom: none !important;
    }
  }
}
