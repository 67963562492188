@import "./_variables.scss";

@import "./searchresults.scss";

.searchpage {
  form {
    margin-bottom: 0 !important;

    .row {
      div[class^="col-"],
      div[class*=" col-"],
      .col {
        margin-top: $spacer;
      }
    }
  }

  &__result-filter-panel {
    &__result-count-select {
      .btn-outline-dark {
        --bs-btn-border-color: #d1d3e2;
        --bs-btn-hover-color: #000;
        --bs-btn-hover-bg: #fff;
        --bs-btn-hover-border-color: #d1d3e2;
        --bs-btn-active-color: #000;
        --bs-btn-active-bg: #fff;
        --bs-btn-active-border-color: #d1d3e2;
      }

      input[type="number"][name="size"] {
        appearance: textfield;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    &__xlsx-download-button {
      color: $white;
      background-color: $teal !important;
      border-color: $teal !important;
    }
  }

  &__result-table {
    .table {
      margin-bottom: 0 !important;
    }
  }
}

.column-sort-button {
  background-color: #ffffff00 !important;
  border: none !important;
  padding: 0px !important;
  svg {
    color: $yellow;
  }
}

#clear-sort-btn {
  margin-right: 2px;
}

.primary-indicator {
  background-color: $red;
  color: $white;
  padding: 0 0.2rem;
  font-size: 10pt;
}

#searchpage-container {
  form {
    margin-bottom: 1rem;
    button {
      margin-right: 0.5rem;
    }
  }
}

// tab nav
.nav-tabs {
  .nav-item {
    border: 1px solid $gray-200 !important;
    &:hover {
      border: 1px solid $gray-500 !important;
      color: $emoryblue;
      border-bottom: none !important;
    }
    border-bottom: none !important;
  }
  #results-tables-tab-case-report.active {
    background-color: $purple;
    border: 1px solid $purple;
    color: white;
  }
  #results-tables-tab-clinical-trial.active {
    background-color: $green;
    border: 1px solid $green;
    color: white;
  }
  #results-tables-tab-observational-result.active {
    background-color: $red;
    border: 1px solid $red;
    color: white;
  }
  .active {
    border-bottom: none !important;
  }
}

@media (min-width: 1200px) {
  .container-table {
    max-width: 100% !important;
    padding: 0px 2rem !important;
  }

  .container-cards {
    max-width: 100% !important;
    padding: 0px 2rem !important;
  }
}

@media (max-width: 576px) {
  #searchpage-container {
    form {
      input,
      select {
        margin-bottom: 2px;
      }
    }
  }
}
