@import "../../../../../../modules/shared/src/styles/variables";

#legal-disclaimer-modal {
  width: 80% !important;
  max-height: 90% !important;

  @media (max-width: $tablet) {
    width: 100% !important;
    max-height: 100% !important;
  }
}
