@import "./_variables.scss";

#footer {
  p {
    font-size: 0.85em;
    color: white;
    max-width: 932px;
    text-align: center !important;
    margin: 0px auto 0px;
    a {
      color: $yellow;
    }
  }
  width: 100%;
  background-color: $emoryblue;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}
