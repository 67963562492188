@import "./_variables.scss";

#homepage-card {
  border: none;
  width: 100% !important;
  .card-body {
    padding: 0.25em;
    .card-title {
      // fix for widths <768px
      display: flex;
      position: absolute;
      top: 60%;
      background-color: white;
      padding: 0.5em 0em;
      color: $black;
      width: 100%;
      align-items: center;
      align-self: center;
      justify-content: center;
      font-size: 1.75em;
      font-family: "Spectral", serif;
    }
    .card-text {
      color: $gray-700;
      width: 100%;
      font-style: italic;
      align-items: center;
      align-self: center;
      justify-content: center;
      text-align: center;
    }
  }
}

@media (max-width: 992px) {
  #homepage-card {
    .card-body {
      .card-title {
        top: 60%;
        padding: 0.25rem;
        font-size: 1.25em;
      }
    }
  }
}

@media (max-width: 575px) {
  #homepage-card {
    .card-body {
      .card-title {
        padding: 1rem;
        font-size: 2.25em;
      }
    }
  }
}

@media (max-width: 470px) {
  #homepage-card {
    .card-body {
      .card-title {
        font-size: 1.5em;
      }
    }
  }
}

@media (max-width: 338px) {
  #homepage-card {
    .card-body {
      .card-title {
        padding: 0.75rem;
        font-size: 1.25em;
      }
    }
  }
}

@media (max-width: 286px) {
  #homepage-card {
    .card-body {
      .card-title {
        padding: 0.5rem;
        font-size: 1em;
      }
    }
  }
}
