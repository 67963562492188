@import "./variables";

#error-msg {
  color: $gray-700;
  margin-left: 1rem;
}

#page-size-selection {
  label {
    &:first-of-type {
      margin-right: 0.5rem;
    }
  }
}

#search-results-list {
  list-style: none;
  padding: 0em;
  li {
    border-left: 5px solid $yellow;
    background-color: $gray-200;
    margin: 1em 0em;
    padding: 0.75em;
    a {
      h3 {
        color: $blue;
        font-weight: 700;
      }
      &:hover {
        h3 {
          color: darken($blue, 33%);
        }
        text-decoration: none;
      }
    }
    p {
      margin-bottom: 0.1em;
      &:nth-of-type(1) {
        color: $gray-800;
      }
      &:nth-of-type(2) {
        font-size: 1.25em;
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        color: $gray-900;
      }
      &:nth-of-type(3) {
        margin-bottom: 1em;
      }
    }
  }
}

#search-results-table {
  thead {
    font-size: 1.1em;
    color: $primary;
    th {
      border-bottom: 3px solid $primary;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          font-size: 1em;
          font-family: "Lato", sans-serif;
          font-weight: 700;
        }
      }
    }
    .outcome-column {
      min-width: 130px;
      width: 130px;
    }
  }
  tbody {
    tr {
      td {
        color: $black;
        a {
          color: $emoryblue;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      td:not(.search-results-table__striped_cell) {
        box-shadow: unset !important;
      }
    }
  }
  .outcomes-table {
    thead {
      tr {
        th {
          // gets it to 16px
          font-size: 0.90909em;
        }
      }
    }
  }
}

.modal-dialog {
  .modal-content {
    .modal-body {
      padding: 0rem 1rem 1rem;
      color: $black;
    }
  }
}
