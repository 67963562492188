.Toastify__toast-container {
  width: auto;
  min-width: 320px;
  .Toastify__toast.Toastify__toast--error {
    width: 640px;
    .msg {
      width: 640px;
      margin-top: 1em;
    }
    .trace {
      margin-top: 1em;
      color: inherit;
    }
  }
}
