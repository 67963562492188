@import "./variables";

.card {
  border: 0 !important;
  border-radius: 0px !important;
  .card-img,
  .card-img-top {
    border-radius: 0px !important;
  }
  .card-body {
    padding: 0.75rem;
  }
}
